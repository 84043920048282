<template>
    <div>
        <div class="row page-title-header">
            <div class="col-12">
                <div class="page-header">
                    <b-breadcrumb class="m-0">
                        <b-breadcrumb-item :to="{
                            name: 'DashboardHome',
                        }">
                            <i class="fa fa-home"></i>
                        </b-breadcrumb-item>
                        <b-breadcrumb-item :to="{
                            name: 'PointRuleList',
                        }">入點比例管理</b-breadcrumb-item>
                        <b-breadcrumb-item :to="{
                            name: 'PointRuleList',
                        }">入點比例列表</b-breadcrumb-item>
                        <b-breadcrumb-item active>入點比例內容</b-breadcrumb-item>
                    </b-breadcrumb>
                </div>
            </div>
        </div>

        <b-card>
            <div class="row mb-2 align-items-end">
                <div class="col-8 d-flex items-center">
                    <h4 class="font-weight-bold">入點比例內容</h4>
                </div>
            </div>

            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
                <section class="row mb-8">
                    <b-table-simple hover small caption-top responsive col-12 col-xl-8>
                        <b-tbody>
                            <b-tr>
                                <b-th>比對值</b-th>
                                <b-td>{{ form.key }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>點數/倍數</b-th>
                                <b-td>{{ form.value }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>比對值類型</b-th>
                                <b-td>{{ form.type }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>活動代碼</b-th>
                                <b-td>{{ form.event_id }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>順序</b-th>
                                <b-td>{{ form.order }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>分類</b-th>
                                <b-td>{{ form.cate }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </section>
            </b-overlay>
        </b-card>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import pointRuleApi from "../../../apis/point-rule";

export default {
    data() {
        return {
            isLoading: false,
            form: {
                key: '',
                value: '',
                type: '',
                event_id: '',
                order: '',
                cate: ''
            },
        };
    },
    computed: {
        ...mapState("general", {
            organization: (state) => state.organization,
        }),
        pointRuleId() {
            return this.$route.params.pointRuleId;
        },
    },
    mounted() {
        this.fetchPointRule();
    },
    methods: {
        async fetchPointRule() {
            try {
                this.isLoading = true;
                const { data } = await pointRuleApi.getPointRule(this.pointRuleId);
                const {
                    key,
                    value,
                    type,
                    event_id,
                    order,
                    cate
                } = data.data;

                this.form = {
                    key,
                    value,
                    type,
                    event_id,
                    order,
                    cate
                };
            } catch (error) {
                console.log("");
            }
            this.isLoading = false;
        },
    },
};
</script>
  
<style lang="scss" scoped>
.content {
    white-space: pre-wrap;
    word-break: break-all;
}

th {
    width: 20%;
}

td {
    white-space: pre-wrap;
    word-break: break-all;
}
</style>
  