import https from "./https"

const pointRuleApi = {
  getPointRules(merchantId, params) {
    return https.get(`admin/merchants/${merchantId}/point-rules`, {params})
  },
  getPointRule(pointRuleId) {
    return https.get(`admin/point-rules/${pointRuleId}`)
  },
  updatePointRule(merchantId, pointRuleId, params) {
    return https.put(`admin/merchants/${merchantId}/point-rules/${pointRuleId}`, params)
  },
  removePointRule(merchantId, pointRuleId) {
    return https.delete(`admin/point-rules/${pointRuleId}`)
  },
  storePointRule(merchantId, params) {
    return https.post(`admin/merchants/${merchantId}/point-rules`, params)
  }
}

export default pointRuleApi
